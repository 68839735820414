<template>
	<el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :show-close="ifEditPassword?false:true"
		append-to-body title="" :visible.sync="passwardVisible" center width="464px" :before-close="handleClose">
		<div slot="title">
			<div class="qs-invite-link-title">{{ifEditPassword?'首次登录请修改密码':'修改密码'}}</div>
		</div>
		<el-form label-width="100px" ref="form" :model="form" :rules="rules" auto-complete="on" label-position="left">
			<el-form-item prop="old_pwd" label="当前密码"><el-input placeholder="请输入当前密码" show-password
					v-model="form.old_pwd"></el-input></el-form-item>
			<el-form-item prop="new_pwd" label="新密码"><el-input placeholder="请输入新密码" show-password
					v-model="form.new_pwd"></el-input></el-form-item>
			<el-form-item prop="new_pwd_two" label="确认新密码"><el-input placeholder="请再次输入新密码" show-password
					v-model="form.new_pwd_two"></el-input></el-form-item>
			<div class="qs-btn-box" v-if="!ifEditPassword">
				<el-button @click="onSubmit('form')" :disabled="passwardChangeStatus"
					class="el-button--primary qs-btn-linear-gradient" size="medium">确 定</el-button>
				<el-button @click="cancel()" class="qs-search-btn-reset" type="info" size="medium">取 消</el-button>
			</div>
			<div class="qs-btn-box qs-btn-box-rt" v-if="ifEditPassword">
				<el-button @click="onSubmit('form')" :disabled="passwardChangeStatus"
					class="el-button--primary qs-btn-linear-gradient" size="medium">确 定</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>

<script>
	import {
		sendSmsCode,
		changePassword
	} from '@/api/system/index'
	import {
		removeToken,
		removeUserInfo,
		setUserInfo
	} from '@/utils/auth'
	import {
		mapState,
		mapActions,
		mapMutations
	} from 'vuex'
	import md5 from "js-md5";
	export default {
		data() {
			return {
				passwardVisible: false,
				form: {
					old_pwd: '',
					new_pwd: '',
					new_pwd_two: '',
				},
				rules: {
					old_pwd: [{
						required: true,
						trigger: 'blur',
						message: '请输入当前密码',
					}, ],
					new_pwd: [{
						required: true,
						trigger: 'blur',
						message: '请输入新密码',
					}, ],
					new_pwd_two: [{
						required: true,
						trigger: 'blur',
						message: '请再次输入新密码',
					}, ],
				},
				passwardChangeStatus: false,
				ifEditPassword: false
			}
		},
		created() {
			this.ifEditPassword = parseFloat(localStorage.getItem('ifEditPassword')) == 0 ? true : false
		},
		methods: {
			...mapActions({
				loginOut: 'user/loginOut',
			}),
			...mapMutations({
				SET_USERINFO: 'user/SET_USERINFO',
				SET_USERNAME: 'user/SET_USERNAME',
				SET_TOKEN: 'user/SET_TOKEN',
				SET_FIRST_REQUEST: 'common/SET_FIRST_REQUEST',
			}),
			// 打开弹窗
			handleOpen() {
				this.passwardVisible = true
			},
			// 关闭页面
			handleClose() {
				this.passwardVisible = false
			},
			cancel() {
				this.$refs.form.resetFields()
				this.passwardVisible = false
			},
			onSubmit(formName) {
				this.passwardChangeStatus = true
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.form.new_pwd != this.form.new_pwd_two) {
							this.$message.error('请确认新密码是否一致！')
							this.passwardChangeStatus = false
							return
						}
						let params = {
							old_pwd: md5(this.form.old_pwd),
							new_pwd: md5(this.form.new_pwd),
							new_pwd_two: md5(this.form.new_pwd_two)
						}
						changePassword(params).then(res => {
							if (res.code == '0000') {
								this.$message.success('密码修改成功，请重新登录！')
								let that = this
								setTimeout(function() {
									that.loginOut().then((res) => {
										if (res.code == '0000') {
											removeToken()
											removeUserInfo()
											that.SET_USERINFO({})
											that.SET_USERNAME('')
											that.SET_TOKEN('')
											that.SET_FIRST_REQUEST(true)
											that.$router.push('/login')
										}
									})
								}, 1000)
							}
						})
						this.passwardChangeStatus = false
					} else {
						this.passwardChangeStatus = false
						return false
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.qs-invite-link-title {
		text-align: left;
		color: #333;
		font-size: 18px;
		font-weight: bold;
		box-sizing: border-box;
		padding-left: 6px;
	}

	.qs-verify-code-box {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.qs-verify-code-input {
			width: 60%;
		}

		.qs-verify-code-btn {
			width: 35%;
			background: #e3eef6;
			border-color: #e3eef6;
			color: #666666;
			padding: 12px 0;
		}
	}

	.qs-btn-box {
		text-align: center;
		border-top: 1px solid #f3f8ff;
		padding-top: 15px;

		&.qs-btn-box-rt {
			text-align: right;
		}
	}

	::v-deep .el-dialog--center .el-dialog__body {
		padding-bottom: 15px;
	}
</style>