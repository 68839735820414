<template>
	<div>
		<!-- 自定义右键菜单 -->
		<div v-if="showContextMenu" :style="contextMenuStyle" class="context-menu">
			<ul>
				<li @click="closeTab(contextMenuTab)">关闭当前标签</li>
				<li @click="closeAllTabs">关闭所有标签</li>
			</ul>
		</div>
		<el-tabs class="tabBox" v-if="historyPages.length>0" v-model="activeTab" type="card" closable
			@tab-click="handleTabClick" @tab-remove="handleTabClose">
			<!-- 使用 v-for 遍历 historyPages 数组，生成历史页面选项卡 -->
			<el-tab-pane v-for="(page, index) in historyPages" :key="page.name" :label="page.label" :name="page.name">
				<!-- @contextmenu.prevent="showContextMenuEvent($event, tab)" -->
				<!-- 在每个选项卡中显示对应的页面内容 -->
				<!-- <router-view></router-view> -->
			</el-tab-pane>
			<!-- <span class="tabCloseAll">关闭所有标签</span> -->
		</el-tabs>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 存储历史页面记录的数组
				historyPages: [],
				// 当前选中的选项卡名称
				activeTab: '',
				showContextMenu: false,
				contextMenuStyle: {
					top: '0px',
					left: '0px'
				},
				contextMenuTab: null
			}
		},
		created() {
			// 刷新系统时 -- 将当前页面添加到历史记录中
			const currentPage = {
				label: this.$route.meta.title, // 当前页面标题
				name: this.$route.name // 当前页面路由名称
			};
			this.addHistoryPage(currentPage);

			// ------------------------------

			this.$router.afterEach((to, from) => {
				// 获取当前路由信息
				const currentPage = {
					label: to.meta.title, // 页面标题
					name: to.name // 路由名称
				};
				const historyPages = this.historyPages
				const exists = historyPages.some(item => item.name === to.name);
				if (!exists) { //跳转页面不存在于历史页面路由数组中再追加
					this.addHistoryPage(currentPage);
				}
			});
		},
		mounted() {
			document.addEventListener('click', this.hideContextMenu);
		},
		beforeDestroy() {
			document.removeEventListener('click', this.hideContextMenu);
		},
		watch: {
			// 监听路由变化，更新 activeTab
			'$route'(to) {
				this.activeTab = to.name;
			}
		},
		methods: {
			// 处理选项卡点击事件
			handleTabClick(tab) {
				this.$router.push({
					name: tab.name
				});
			},
			saveTabHistory(tabName) {
				const index = this.tabHistory.indexOf(tabName);
				if (index !== -1) {
					this.tabHistory.splice(index, 1); // 如果已存在，则先移除
				}
				this.tabHistory.push(tabName); // 再添加到末尾
				localStorage.setItem('tabHistory', JSON.stringify(this.tabHistory)); // 保存到 localStorage
				localStorage.setItem('activeTab', tabName); // 保存当前激活的标签到 localStorage
			},
			// 处理选项卡关闭事件
			handleTabClose(targetName) {
				if (this.historyPages.length == 1) {
					this.$router.push({
						name: 'resourceManagementList'
					});
					this.historyPages = [];
					this.activeTab = '';
					return
				}
				const tabs = this.historyPages;
				let activeName = this.activeTab;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							const nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}
				this.activeTab = activeName;
				this.historyPages = tabs.filter(tab => tab.name !== targetName);
				if (this.historyPages.length > 0) {
					this.$router.push({
						name: this.historyPages[this.historyPages.length - 1].name
					});
				}
			},
			// 将页面添加到历史记录中
			addHistoryPage(page) {
				// 检查历史记录中是否已存在该页面，如果存在则先移除
				const index = this.historyPages.findIndex(item => item.name === page.name);
				if (index !== -1) {
					this.historyPages.splice(index, 1);
				}
				// 将页面添加到历史记录的开头
				// this.historyPages.unshift(page);
				this.historyPages.push(page);
				// 更新当前选中的选项卡为最新添加的页面
				this.activeTab = page.name;
			},
			closeTab(tab) {
				this.removeTab(tab.name);
				this.hideContextMenu();
			},
			closeAllTabs() {
				this.$router.push({
					name: 'resourceManagementList'
				});
				this.historyPages = [];
				this.activeTab = '';
				// this.hideContextMenu();
			},
			showContextMenuEvent(event, tab) {
				console.log(111)
				this.contextMenuTab = tab;
				this.showContextMenu = true;
				this.contextMenuStyle.top = `${event.clientY}px`;
				this.contextMenuStyle.left = `${event.clientX}px`;
			},
			hideContextMenu() {
				this.showContextMenu = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-tabs--card>.el-tabs__header {
		border-bottom: 1px solid #E4E7ED;
		background: #fff;
	}

	::v-deep .el-tabs--card {
		.el-tabs__header {
			border-bottom: none;
			background: #FCFCFC;

			.el-tabs__item {
				border: none;
				border-radius: 4px;
				margin-right: 6px;
				position: relative;
				background: #FCFCFC;
				height: 43px;
				line-height: 43px;
				color: #666666;
				padding-left: 20px;

				&.is-closable {
					&:hover {
						padding-left: 20px;
						
						.el-icon-close{
							width: 16px !important;
							height: 16px;
							line-height: 16px;
						}
					}
				}

				.el-icon-close {
					font-size: 16px;
					top: 0;
				}

				// &::before {
				// 	display: block;
				// 	content: '';
				// 	width: 5px;
				// 	height: 5px;
				// 	background: #DEDEE8;
				// 	border-radius: 50%;
				// 	position: absolute;
				// 	top: 50%;
				// 	left: 9px;
				// 	transform: translateY(-50%);
				// }

				&.is-active {
					color: #2469FF;
					background: #F7F8FA;
					border-radius: 0px 0px 0px 0px;
					border-top: 1px solid #517FFD;

					// &::before {
					// 	display: block;
					// 	content: '';
					// 	width: 5px;
					// 	height: 5px;
					// 	background: #2469FF;
					// 	border-radius: 50%;
					// 	position: absolute;
					// 	top: 50%;
					// 	left: 9px;
					// 	transform: translateY(-50%);
					// }
					.el-icon-close{
						width: 16px !important;
						height: 16px;
						line-height: 16px;
					}
				}
			}
		}
	}

	.context-menu {
		position: absolute;
		background-color: #fff;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		z-index: 1000;
	}

	.context-menu ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.context-menu li {
		padding: 8px 12px;
		cursor: pointer;
	}

	.context-menu li:hover {
		background-color: #f5f5f5;
	}

	.tabBox {
		position: relative;

		.tabCloseAll {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
</style>