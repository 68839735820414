import layout from '@/layout/index.vue'
const talent = {
	path: '/configure',
	component: layout,
	name: 'setting',
	redirect: '/setting/commonSetting',
	meta: {
		title: '配置',
		isSideBar: true
	},
	children: [{
		path: '/setting/commonSetting',
		component: () => import('@/views/setting/talentSetting/index.vue'),
		name: 'settingTalent',
		redirect: '/setting/talentSetting/allSetting',
		meta: {
			title: '通用设置'
		},
		children: [{
			path: '/setting/talentSetting/allSetting',
			component: () => import('@/views/setting/talentSetting/index.vue'),
			name: 'settingTalentAllSetting',
			redirect: '/setting/talentSetting/allSetting/list',
			meta: {
				title: '人才管理'
			},
			children: [{
				path: '/setting/talentSetting/allSetting/list',
				component: () => import('@/views/setting/talentSetting/list/index.vue'),
				name: 'settingTalentAllSettingList',
				meta: {
					title: '人才管理',
					isShow: true,
					activeMenu: '/setting/talentSetting/allSetting'
				},
			}]
		}, {
			path: '/setting/jobSetting/allSetting',
			component: () => import('@/views/setting/jobSetting/index.vue'),
			name: 'settingJobAllSetting',
			redirect: '/setting/jobSetting/allSetting/list',
			meta: {
				title: '职位管理'
			},
			children: [{
				path: '/setting/jobSetting/allSetting/list',
				component: () => import('@/views/setting/jobSetting/list/index.vue'),
				name: 'settingJobAllSettingList',
				meta: {
					title: '职位管理',
					isShow: true,
					activeMenu: '/setting/jobSetting/allSetting'
				},
			}]
		}, {
			path: '/setting/processSetting/allSetting',
			component: () => import('@/views/setting/processSetting/index.vue'),
			name: 'settingProcessAllSetting',
			redirect: '/setting/processSetting/allSetting/list',
			meta: {
				title: '进程管理'
			},
			children: [{
				path: '/setting/processSetting/allSetting/list',
				component: () => import('@/views/setting/processSetting/list/index.vue'),
				name: 'settingProcessAllSettingList',
				meta: {
					title: '进程管理',
					isShow: true,
					activeMenu: '/setting/processSetting/allSetting'
				},
			}]
		}, {
			path: '/setting/tagSetting/allSetting',
			component: () => import('@/views/setting/tagSetting/index.vue'),
			name: 'settingTagAllSetting',
			redirect: '/setting/tagSetting/allSetting/list',
			meta: {
				title: '标签类别'
			},
			children: [{
				path: '/setting/tagSetting/allSetting/list',
				component: () => import('@/views/setting/tagSetting/list/index.vue'),
				name: 'settingTagAllSettingList',
				meta: {
					title: '标签类别',
					isShow: true,
					activeMenu: '/setting/tagSetting/allSetting'
				},
			}]
		}]
	}, {
		path: '/setting/authSetting',
		component: () => import('@/views/setting/userSetting/index.vue'),
		name: 'settingTalent',
		redirect: '/setting/userSetting/allSetting',
		meta: {
			title: '权限管理'
		},
		children: [{
			path: '/setting/userSetting/allSetting',
			component: () => import('@/views/setting/userSetting/index.vue'),
			name: 'settingUserAllSetting',
			redirect: '/setting/userSetting/allSetting/list',
			meta: {
				title: '用户管理'
			},
			children: [{
				path: '/setting/userSetting/allSetting/list',
				component: () => import('@/views/setting/userSetting/list/index.vue'),
				name: 'settingUserAllSettingList',
				meta: {
					title: '用户管理',
					isShow: true,
					activeMenu: '/setting/userSetting/allSetting'
				},
			}]
		}, {
			path: '/setting/roleSetting/allSetting',
			component: () => import('@/views/setting/roleSetting/index.vue'),
			name: 'settingRoleAllSetting',
			redirect: '/setting/roleSetting/allSetting/list',
			meta: {
				title: '角色管理'
			},
			children: [{
				path: '/setting/roleSetting/allSetting/list',
				component: () => import('@/views/setting/roleSetting/list/index.vue'),
				name: 'settingRoleAllSettingList',
				meta: {
					title: '角色管理',
					isShow: true,
					activeMenu: '/setting/roleSetting/allSetting'
				},
			}, {
				path: '/setting/roleSetting/editRole',
				component: () => import('@/views/setting/roleSetting/list/edit.vue'),
				name: 'settingRoleEdit',
				meta: {
					title: '角色编辑',
					isShow: false,
					activeMenu: '/setting/roleSetting/allSetting'
				},
			}]
		}]
	}]
}
export default talent