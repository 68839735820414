import layout from '@/layout/index.vue'
const talent = {
	path: '/operation',
	component: layout,
	name: 'operation',
	redirect: '/operation/resource',
	meta: {
		title: '工作台',
		isSideBar: true
	},
	children: [{
		path: '/operation/resource',
		component: () => import('@/views/operation/resource/index.vue'),
		name: 'operationClassify',
		redirect: '/operation/resource/voiceCommunication',
		meta: {
			title: '语音通信',
			iconName: 'yytongxin'
		},
		children: [{
			path: '/operation/resource/voiceCommunication',
			component: () => import('@/views/operation/resource/voiceCommunication/index.vue'),
			name: 'voiceCommunication',
			redirect: '/operation/resource/voiceCommunication/list',
			meta: {
				title: '资源管理'
			},
			children: [{
				path: '/operation/resource/voiceCommunication/list',
				component: () => import('@/views/operation/resource/voiceCommunication/list.vue'),
				name: 'resourceManagementList',
				meta: {
					title: '资源管理',
					isShow: true,
					activeMenu: '/operation/resource/voiceCommunication'
				},
			}, {
				path: '/operation/resource/voiceCommunication/add',
				component: () => import('@/views/operation/resource/voiceCommunication/add.vue'),
				name: 'resourceManagementAdd',
				meta: {
					title: '新增资源',
					isShow: true,
					activeMenu: '/operation/resource/voiceCommunication'
				},
			}, {
				path: '/operation/resource/voiceCommunication/detail',
				component: () => import('@/views/operation/resource/voiceCommunication/detail.vue'),
				name: 'resourceManagementDetail',
				meta: {
					title: '资源详情',
					isShow: true,
					activeMenu: '/operation/resource/voiceCommunication'
				},
			}, {
				path: '/operation/resource/voiceCommunication/edit',
				component: () => import('@/views/operation/resource/voiceCommunication/edit.vue'),
				name: 'resourceManagementEdit',
				meta: {
					title: '编辑资源',
					isShow: true,
					activeMenu: '/operation/resource/voiceCommunication'
				},
			}, {
				path: '/operation/resource/voiceCommunication/enterpriseDetails',
				component: () => import('@/views/operation/resource/voiceCommunication/enterpriseDetails.vue'),
				name: 'enterpriseDetails',
				meta: {
					title: '企业详情',
					isShow: true,
					activeMenu: '/operation/resource/voiceCommunication'
				},
			}]
		}]
	}, {
		path: '/operation/supplierManagement',
		component: () => import('@/views/operation/supplierManagement/index.vue'),
		name: 'supplierManagement',
		meta: {
			title: '供应商管理',
			iconName: 'supplier'
		},
		children: [{
			path: '/operation/supplierManagement/supplierList',
			component: () => import('@/views/operation/supplierManagement/supplier/index.vue'),
			name: 'supplierAll',
			redirect: '/operation/supplierManagement/supplier/list',
			meta: {
				title: '供应商管理'
			},
			children: [{
				path: '/operation/supplierManagement/supplier/list',
				component: () => import('@/views/operation/supplierManagement/supplier/list.vue'),
				name: 'supplierList',
				meta: {
					title: '供应商管理',
					isShow: true,
					activeMenu: '/operation/supplierManagement/supplierList'
				},
			}]
		}]
	}, {
		path: '/operation/channelManagement',
		component: () => import('@/views/operation/channelManagement/index.vue'),
		name: 'channelManagement',
		meta: {
			title: '渠道管理',
			iconName: 'channel'
		},
		children: [{
			path: '/operation/channelManagement/channelList',
			component: () => import('@/views/operation/channelManagement/channel/index.vue'),
			name: 'channelAll',
			redirect: '/operation/channelManagement/channel/list',
			meta: {
				title: '渠道管理'
			},
			children: [{
				path: '/operation/channelManagement/channel/list',
				component: () => import('@/views/operation/channelManagement/channel/list.vue'),
				name: 'channelList',
				meta: {
					title: '渠道管理',
					isShow: true,
					activeMenu: '/operation/channelManagement/channelList'
				},
			}]
		}]
	}, {
		path: '/operation/numberManagement',
		component: () => import('@/views/operation/numberManagement/index.vue'),
		name: 'numberManagement',
		meta: {
			title: '号码管理',
			iconName: 'worknum'
		},
		children: [{
			path: '/operation/numberManagement/workNumList',
			component: () => import('@/views/operation/numberManagement/workNumList/index.vue'),
			name: 'numberManagementAll',
			redirect: '/operation/numberManagement/workNumList/list',
			meta: {
				title: '工作号管理'
			},
			children: [{
				path: '/operation/numberManagement/workNumList/list',
				component: () => import('@/views/operation/numberManagement/workNumList/list.vue'),
				name: 'workNumList',
				meta: {
					title: '工作号管理',
					isShow: true,
					activeMenu: '/operation/numberManagement/workNumList'
				},
			}]
		}, {
			path: '/operation/numberManagement/directCallList',
			component: () => import('@/views/operation/numberManagement/directCall/index.vue'),
			name: 'directCallAll',
			redirect: '/operation/numberManagement/directCall/list',
			meta: {
				title: '语音池管理'
			},
			children: [{
				path: '/operation/numberManagement/directCall/list',
				component: () => import('@/views/operation/numberManagement/directCall/list.vue'),
				name: 'directCall',
				meta: {
					title: '语音池管理',
					isShow: true,
					activeMenu: '/operation/numberManagement/directCallList'
				},
			}]
		}, {
			path: '/operation/numberManagement/callBackList',
			component: () => import('@/views/operation/numberManagement/callBackNum/index.vue'),
			name: 'callBackNumAll',
			redirect: '/operation/numberManagement/callBackNum/list',
			meta: {
				title: '回铃号管理'
			},
			children: [{
				path: '/operation/numberManagement/callBackNum/list',
				component: () => import('@/views/operation/numberManagement/callBackNum/list.vue'),
				name: 'callBackNum',
				meta: {
					title: '回铃号管理',
					isShow: true,
					activeMenu: '/operation/numberManagement/callBackList'
				},
			}]
		}]
	}, {
		path: '/operation/voicePoolManagement',
		component: () => import('@/views/operation/voicePoolManagement/index.vue'),
		name: 'voicePoolManagement',
		meta: {
			title: '语音池管理',
			iconName: 'voicePool'
		},
		children: [{
			path: '/operation/voicePoolManagement/openedNum',
			component: () => import('@/views/operation/voicePoolManagement/openedNum/index.vue'),
			name: 'openedNum',
			redirect: '/operation/voicePoolManagement/openedNum/list',
			meta: {
				title: '已开通号码'
			},
			children: [{
				path: '/operation/voicePoolManagement/openedNum/list',
				component: () => import('@/views/operation/voicePoolManagement/openedNum/list.vue'),
				name: 'openedNumList',
				meta: {
					title: '已开通号码',
					isShow: true,
					activeMenu: '/operation/voicePoolManagement/openedNum'
				},
			}]
		}, {
			path: '/operation/voicePoolManagement/unsubscribeNum',
			component: () => import('@/views/operation/voicePoolManagement/unsubscribeNum/index.vue'),
			name: 'unsubscribeNum',
			redirect: '/operation/voicePoolManagement/unsubscribeNum/list',
			meta: {
				title: '已退订号码'
			},
			children: [{
				path: '/operation/voicePoolManagement/unsubscribeNum/list',
				component: () => import('@/views/operation/voicePoolManagement/unsubscribeNum/list.vue'),
				name: 'unsubscribeNumList',
				meta: {
					title: '已退订号码',
					isShow: true,
					activeMenu: '/operation/voicePoolManagement/unsubscribeNum'
				},
			}]
		}]
	}, {
		path: '/operation/ringbackNumber',
		component: () => import('@/views/operation/ringbackNumber/index.vue'),
		name: 'ringbackNumber',
		meta: {
			title: '回铃号管理',
			iconName: 'ringback'
		},
		children: [{
			path: '/operation/ringbackNumber/allNumber',
			component: () => import('@/views/operation/ringbackNumber/allNumber/index.vue'),
			name: 'ringbackNumberA',
			redirect: '/operation/ringbackNumber/allNumber/list',
			meta: {
				title: '号码池'
			},
			children: [{
				path: '/operation/ringbackNumber/allNumber/list',
				component: () => import('@/views/operation/ringbackNumber/allNumber/list.vue'),
				name: 'ringbackNumberAll',
				meta: {
					title: '号码池',
					isShow: true,
					activeMenu: '/operation/ringbackNumber/allNumber'
				},
			}]
		}, {
			path: '/operation/ringbackNumber/assigned',
			component: () => import('@/views/operation/ringbackNumber/assigned/index.vue'),
			name: 'assignedRingbackNum',
			redirect: '/operation/ringbackNumber/assigned/list',
			meta: {
				title: '已分配号码'
			},
			children: [{
				path: '/operation/ringbackNumber/assigned/list',
				component: () => import('@/views/operation/ringbackNumber/assigned/list.vue'),
				name: 'assignedRingbackNumList',
				meta: {
					title: '已分配号码',
					isShow: true,
					activeMenu: '/operation/ringbackNumber/assigned'
				},
			}]
		}, {
			path: '/operation/ringbackNumber/marked',
			component: () => import('@/views/operation/ringbackNumber/marked/index.vue'),
			name: 'markedRingBackNum',
			redirect: '/operation/ringbackNumber/marked/list',
			meta: {
				title: '已标记号码'
			},
			children: [{
				path: '/operation/ringbackNumber/marked/list',
				component: () => import('@/views/operation/ringbackNumber/marked/list.vue'),
				name: 'markedRingBackNumList',
				meta: {
					title: '已标记号码',
					isShow: true,
					activeMenu: '/operation/ringbackNumber/marked'
				},
			}]
		}, {
			path: '/operation/ringbackNumber/recycled',
			component: () => import('@/views/operation/ringbackNumber/recycled/index.vue'),
			name: 'recycledRingbackNum',
			redirect: '/operation/ringbackNumber/recycled/list',
			meta: {
				title: '回收站'
			},
			children: [{
				path: '/operation/ringbackNumber/recycled/list',
				component: () => import('@/views/operation/ringbackNumber/recycled/list.vue'),
				name: 'recycledRingbackNumList',
				meta: {
					title: '回收站',
					isShow: true,
					activeMenu: '/operation/ringbackNumber/recycled'
				},
			}]
		}]
	}, {
		path: '/operation/companyManagement',
		component: () => import('@/views/operation/companyManagement/index.vue'),
		name: 'companyManagement',
		meta: {
			title: '企业管理',
			iconName: 'company'
		},
		children: [{
			path: '/operation/companyManagement/agentComlist',
			component: () => import('@/views/operation/companyManagement/company/agentComlist.vue'),
			name: 'companyAgentAll',
			redirect: '/operation/companyManagement/company/agentComlist',
			meta: {
				title: '渠道企业'
			},
			children: [{
				path: '/operation/companyManagement/company/agentComlist',
				component: () => import('@/views/operation/companyManagement/company/agentComlist.vue'),
				name: 'agentComlist',
				meta: {
					title: '渠道企业',
					isShow: true,
					activeMenu: '/operation/companyManagement/agentComlist'
				},
			}]
		}, {
			path: '/operation/companyManagement/companyCallerList',
			component: () => import('@/views/operation/companyManagement/company/companyCallerList.vue'),
			name: 'companyCallerAll',
			redirect: '/operation/companyManagement/company/companyCallerList',
			meta: {
				title: '主叫管理'
			},
			children: [{
				path: '/operation/companyManagement/company/companyCallerList',
				component: () => import('@/views/operation/companyManagement/company/companyCallerList.vue'),
				name: 'companyCallerList',
				meta: {
					title: '主叫管理',
					isShow: true,
					activeMenu: '/operation/companyManagement/companyCallerList'
				},
			}]
		}]
	}, {
		path: '/operation/reportingMaterials',
		component: () => import('@/views/operation/reportingMaterials/index.vue'),
		name: 'reportingMaterials',
		meta: {
			title: '报备材料',
			iconName: 'reportingMaterials'
		},
		children: [{
			path: '/operation/reportingMaterials/templatelist',
			component: () => import('@/views/operation/reportingMaterials/template/index.vue'),
			name: 'templateAll',
			redirect: '/operation/reportingMaterials/template/list',
			meta: {
				title: '材料模板'
			},
			children: [{
				path: '/operation/reportingMaterials/template/list',
				component: () => import('@/views/operation/reportingMaterials/template/list.vue'),
				name: 'templateList',
				meta: {
					title: '材料模板',
					isShow: true,
					activeMenu: '/operation/reportingMaterials/templatelist'
				},
			}]
		}, {
			path: '/operation/reportingMaterials/templateFieldlist',
			component: () => import('@/views/operation/reportingMaterials/templateField/index.vue'),
			name: 'templateFieldAll',
			redirect: '/operation/reportingMaterials/templateField/list',
			meta: {
				title: '模板字段'
			},
			children: [{
				path: '/operation/reportingMaterials/templateField/list',
				component: () => import('@/views/operation/reportingMaterials/templateField/index.vue'),
				name: 'templateFieldList',
				meta: {
					title: '模板字段',
					isShow: true,
					activeMenu: '/operation/reportingMaterials/templateFieldlist'
				},
			}, {
				path: '/operation/reportingMaterials/templateField/add',
				component: () => import('@/views/operation/reportingMaterials/templateField/add.vue'),
				name: 'templateFieldAdd',
				meta: {
					title: '新增字段',
					isShow: true,
					activeMenu: '/operation/reportingMaterials/add'
				},
			}, {
				path: '/operation/reportingMaterials/templateField/edit',
				component: () => import('@/views/operation/reportingMaterials/templateField/edit.vue'),
				name: 'templateFieldEdit',
				meta: {
					title: '修改字段',
					isShow: true,
					activeMenu: '/operation/reportingMaterials/edit'
				},
			}]
		}]
	}, {
		path: '/operation/financeManagement',
		component: () => import('@/views/operation/financeManagement/index.vue'),
		name: 'financeManagement',
		meta: {
			title: '财务管理',
			iconName: 'financeManage'
		},
		children: [{
			path: '/operation/financeManagement/platformBalanceList',
			component: () => import('@/views/operation/financeManagement/platformBalance/index.vue'),
			name: 'platformBalanceAll',
			redirect: '/operation/financeManagement/platformBalance/list',
			meta: {
				title: '平台余额流水明细'
			},
			children: [{
				path: '/operation/financeManagement/platformBalance/list',
				component: () => import('@/views/operation/financeManagement/platformBalance/list.vue'),
				name: 'platformBalanceList',
				meta: {
					title: '平台余额流水明细',
					isShow: true,
					activeMenu: '/operation/financeManagement/platformBalanceList'
				},
			}]
		}, {
			path: '/operation/financeManagement/agentBalanceList',
			component: () => import('@/views/operation/financeManagement/agentBalance/index.vue'),
			name: 'agentBalanceAll',
			redirect: '/operation/financeManagement/agentBalance/list',
			meta: {
				title: '渠道余额流水明细'
			},
			children: [{
				path: '/operation/financeManagement/agentBalance/list',
				component: () => import('@/views/operation/financeManagement/agentBalance/list.vue'),
				name: 'agentBalance',
				meta: {
					title: '渠道余额流水明细',
					isShow: true,
					activeMenu: '/operation/financeManagement/agentBalanceList'
				},
			}]
		}, {
			path: '/operation/financeManagement/supplierBalanceList',
			component: () => import('@/views/operation/financeManagement/supplierBalance/index.vue'),
			name: 'supplierBalanceAll',
			redirect: '/operation/financeManagement/supplierBalance/list',
			meta: {
				title: '供应商余额流水明细'
			},
			children: [{
				path: '/operation/financeManagement/supplierBalance/list',
				component: () => import('@/views/operation/financeManagement/supplierBalance/list.vue'),
				name: 'supplierBalanceList',
				meta: {
					title: '供应商余额流水明细',
					isShow: true,
					activeMenu: '/operation/financeManagement/supplierBalanceList'
				},
			}]
		}]
	}, {
		path: '/operation/cdrManagement',
		component: () => import('@/views/operation/cdrManagement/index.vue'),
		name: 'cdrManagement',
		meta: {
			title: '话单管理',
			iconName: 'cdrManage'
		},
		children: [{
			path: '/operation/cdrManagement/cdrListAgent',
			component: () => import('@/views/operation/cdrManagement/cdr/index.vue'),
			name: 'agentlist',
			redirect: '/operation/cdrManagement/cdr/agentlist',
			meta: {
				title: '渠道话单管理'
			},
			children: [{
				path: '/operation/cdrManagement/cdr/agentlist',
				component: () => import('@/views/operation/cdrManagement/cdr/agentlist.vue'),
				name: 'cdrListAgent',
				meta: {
					title: '渠道话单管理',
					isShow: true,
					activeMenu: '/operation/cdrManagement/cdrListAgent'
				},
			}]
		}]
	}, {
		path: '/operation/systemManagement',
		component: () => import('@/views/operation/systemManagement/index.vue'),
		name: 'systemManagement',
		meta: {
			title: '系统管理',
			iconName: 'systemManage'
		},
		children: [{
			path: '/operation/systemManagement/userList',
			component: () => import('@/views/operation/systemManagement/user/index.vue'),
			name: 'userManage',
			redirect: '/operation/systemManagement/user/list',
			meta: {
				title: '用户管理'
			},
			children: [{
				path: '/operation/systemManagement/user/list',
				component: () => import('@/views/operation/systemManagement/user/list.vue'),
				name: 'userList',
				meta: {
					title: '用户管理',
					isShow: true,
					activeMenu: '/operation/systemManagement/userList'
				},
			}]
		}, {
			path: '/operation/systemManagement/industryList',
			component: () => import('@/views/operation/systemManagement/industry/index.vue'),
			name: 'industryAll',
			redirect: '/operation/systemManagement/industry/list',
			meta: {
				title: '行业管理'
			},
			children: [{
				path: '/operation/systemManagement/industry/list',
				component: () => import('@/views/operation/systemManagement/industry/list.vue'),
				name: 'industryList',
				meta: {
					title: '行业管理',
					isShow: true,
					activeMenu: '/operation/systemManagement/industryList'
				},
			}]
		}, {
			path: '/operation/systemManagement/logsList',
			component: () => import('@/views/operation/systemManagement/logs/index.vue'),
			name: 'logsManage',
			redirect: '/operation/systemManagement/logs/list',
			meta: {
				title: '日志管理'
			},
			children: [{
				path: '/operation/systemManagement/logs/list',
				component: () => import('@/views/operation/systemManagement/logs/list.vue'),
				name: 'logsList',
				meta: {
					title: '日志管理',
					isShow: true,
					activeMenu: '/operation/systemManagement/logsList'
				},
			}]
		}]
	}]
}
export default talent