<template>
	<div class="qs-user-wrapper clearfix">
		<div class="qs-documents" @click="handleClick('documents')">接口文档</div>
		<el-badge :value="200" :max="99" class="badge-item" v-if="false">
			<el-popover placement="bottom-end" width="480" trigger="click">
				<div class="message">
					<div class="message_box" v-for="(item, index) in 20" :key="index">
						<img class="message_img" :src="`/images/${index % 3 == 0 ? 'message_unread' : 'message_read'}.png`" />
						<div class="message_right">
							<div class="message_right_top">
								<div class="message_title">余额不足提醒</div>
								<div class="message_time">今天 12:30:01</div>
							</div>
							<div class="message_conten">尊敬的Andy，您当前的余额：0.0000元，根据您最近7天的消费统计，您的余额可能不足以支撑本日消费，请及时充值。</div>
						</div>
					</div>
					<div class="message_button">
						<el-button type="text">全部已读</el-button>
						<el-button type="text">关闭</el-button>
					</div>
				</div>
				<i class="el-icon-bell" slot="reference"></i>
			</el-popover>
		</el-badge>
		<div class="qs-user-info">
			{{ name }}
			<i class="el-icon-caret-bottom"></i>
			<!-- 个人中心下拉弹窗 -->
			<div class="qs-user-menulist-system">
				<div class="text" @click="handleClick('oprateLogs')">操作日志</div>
				<div class="text" @click="handleClick('editPassward')">修改密码</div>
				<div class="text" @click="handleClick('loginOut')">退出登录</div>
			</div>
		</div>
		<!-- 操作日志 -->
		<el-drawer :visible.sync="logsDrawer" size="70%" :show-close="false" :with-header="false" :wrapper-closable="true" :before-close="handleClose">
			<oprateLogs ref="refOprateLogs" @closeDrawer="closeLogsDrawer"></oprateLogs>
		</el-drawer>
		<!-- 修改密码 -->
		<editPasswardModal ref="editPasswardModal"></editPasswardModal>
	</div>
</template>
<script>
	import messageIcon from '@/assets/images/layout/news.png'
	import oprateLogs from '@/components/oprateLogs.vue'
	import editPasswardModal from '@/components/editPasswardModal.vue'
	import {
		mapActions,
		mapMutations,
		mapState
	} from 'vuex'
	import {
		unbindWechat,
		companyList
	} from '@/api/system/index'
	import {
		setUserInfo,
		removeToken,
		removeSelectCompanyStateId,
		removeUserInfo,
		removeRequestData,
	} from '@/utils/auth'
	export default {
		components: {
			oprateLogs,
			editPasswardModal,
		},
		data() {
			return {
				inviteGuide: false,
				accountSettingDrawer: false,
				logsDrawer: false,
				remindListDrawer: false,
				isShowRedCircle: false,
				role_name: localStorage.getItem('role_name'),
				isShowChangeCom: false,
				activeName: 0
			}
		},
		computed: {
			...mapState({
				name: (state) => state.user.name,
				userInfo: (state) => state.user.userInfo,
			}),
		},
		methods: {
			...mapMutations({
				SET_USERINFO: 'user/SET_USERINFO',
				SET_AGENTINFO: 'user/SET_AGENTINFO',
				SET_WECHAT_BIND: 'user/SET_WECHAT_BIND',
				SET_USERNAME: 'user/SET_USERNAME',
				SET_TOKEN: 'user/SET_TOKEN',
				SET_FIRST_REQUEST: 'common/SET_FIRST_REQUEST',
			}),
			...mapActions({
				loginOut: 'user/loginOut',
				initUserInfo: 'user/initUserInfo',
				initRemindNotice: 'common/initRemindNotice',
			}),
			getCompanyList() {
				companyList().then((res) => {
					if (res.code == '0000') {
						if (res.data.length > 1) {
							this.isShowChangeCom = true
						} else {
							this.isShowChangeCom = false
						}
					}
				}).catch((err) => {
					// console.log(err)
				})
			},
			getList() {},
			getRemindList() {
				this.remindListDrawer = true
				setTimeout(() => {
					this.$refs.refRemindList.initData()
				}, 200)
			},
			initRemind() {
				this.initRemindNotice().then((res) => {
					if (res.code == '0000') {
						if (res.data != null) {
							this.isShowRedCircle = true
						}
					}
				}).catch(() => {})
			},
			updateUserinfo() {
				this.initUserInfo()
			},
			closeDrawer(val) {
				this.accountSettingDrawer = false
			},
			closeLogsDrawer(val) {
				this.logsDrawer = false
			},
			handleLoginOut() {
				this.$confirm('确定退出吗？', '提示', {
					confirmButtonText: '确 定',
					confirmButtonClass: 'confirmbtn', //自定义class
					cancelButtonText: '取 消',
					type: 'warning',
				}).then(() => {
					this.loginOut().then((res) => {
						if (res.code == '0000') {
							removeToken()
							removeUserInfo()
							this.SET_USERINFO({})
							this.SET_AGENTINFO({})
							this.SET_USERNAME('')
							this.SET_TOKEN('')
							this.SET_FIRST_REQUEST(true)
							this.$router.push('/login')
						}
					})
				}).catch(() => {})
			},
			handleClose() {
				this.accountSettingDrawer = false
				this.logsDrawer = false
				this.remindListDrawer = false
			},
			cancelWechatQrcode() {
				this.$confirm('确定解除微信绑定吗?', '提示', {
					confirmButtonText: '确 定',
					confirmButtonClass: 'confirmbtn', //自定义class
					cancelButtonText: '取 消',
					type: 'warning',
				}).then(() => {
					unbindWechat().then((res) => {
						if (res.code == '0000') {
							this.$message.success(res.msg)
							setUserInfo(JSON.stringify(this.userInfo))
						}
					}).catch(() => {})
				}).catch(() => {})
			},
			handleClick(eventVal) {
				switch (eventVal) {
					case 'accountSetting':
						//账号设置
						// this.accountSettingDrawer = true
						break
					case 'userManage':
						//用户管理
						// this.$router.push('/setting/userSetting/allSetting/list')
						break
					case 'invite':
						//邀请成员
						// this.$refs.refInviteMemberStep1.handleOpen()
						break
					case 'identificationCode':
						this.$refs.identificationCode.handleOpen()
						break
					case 'oprateLogs':
						this.$router.push('/operation/systemManagement/logs/list')
						break
					case 'editPassward':
						this.$refs.editPasswardModal.handleOpen()
						break
					case 'bindWechat':
						//绑定微信
						// this.$refs.refBindWechat.getWechatQrcode()
						break
					case 'cancelWechat':
						// this.cancelWechatQrcode()
						break
					case 'selectCom':
						//选择企业
						// this.$refs.refSelectCompany.handleOpen()
						break
					case 'loginOut':
						//退出系统
						this.handleLoginOut()
						break
					case 'addTalent':
						// this.$router.push('/talent/classify/allTalent/add')
						break
					case 'addJob':
						// this.$router.push('/job/classify/allJob/add')
						break
					case 'editPassward':
						this.$refs.editPasswardModal.handleOpen()
						break
					case 'newsList':
						this.remindListDrawer = true
						this.isShowRedCircle = false
						// setTimeout(() => {
						// 	this.$refs.refRemindList.initData()
						// }, 200)
						break
					case 'documents':
						window.open('http://doc.open10086.com', '_blank');
						break
					default:
						break
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.message {
		height: 544px;
		overflow: auto;
		padding: 0px 10px 40px;
		
		.message_box {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 16px 0;
			border-bottom: 1px solid #F2F3F5;
			
			&:last-child {
				border-bottom: none;
			}
			
			.message_img {
				width: 32px;
				height: 32px;
				margin-right: 12px;
			}

			.message_right {
				.message_right_top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					
					.message_title {
						font-size: 16px;
						color: #212429;
						font-weight: bold;
					}
					
					.message_time {
						font-size: 12px;
						color: #8D9299;
					}
				}
				
				.message_conten {
					font-size: 14px;
					color: #636A75;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
		
		.message_button {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 56px;
			line-height: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			background: #FFF;
			color: #4A90E2;
			border-top: 1px solid #F2F3F5;
			cursor: pointer;
		}
	}

	.qs-user-wrapper {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 50%;
		padding-right: 32px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 68px;

		.qs-user-news {
			height: 100%;
			background: url('@assets/images/layout/news.png') no-repeat left center / 22px 22px;
			cursor: pointer;
			margin: 0 24px 0 0;
			position: relative;
			line-height: 60px;
			padding-left: 20px;
			font-size: 14px;
			color: #333;
			position: relative;

			.info-nums {
				color: #fff;
				font-size: 12px;
				background: #FF0000;
				position: absolute;
				top: 15px;
				right: -12px;
				height: 14px;
				line-height: 14px;
				padding: 0 3px;
				border-radius: 6px;
			}

			.qs-isShowRedCircle {
				display: block;
				width: 6px;
				height: 6px;
				background: #ff0000;
				border-radius: 50%;
				position: absolute;
				top: 26px;
				right: 3px;
			}
		}

		.qs-user-serach {
			width: 30px;
			height: 100%;
			background: url('@assets/images/layout/search.png') no-repeat center center / 18px 14px;
			cursor: pointer;
			margin: 0 24px 0 26px;
		}

		.qs-log-out {
			float: right;
			height: 100%;
			line-height: 70px;
			text-align: center;
			padding: 0 30px;
			cursor: pointer;
		}

		.qs-documents {
			background: #F7F8FA;
			border-radius: 8px 8px 8px 8px;
			font-size: 16px;
			padding: 6px 12px;
			font-size: 16px;
			color: $themeColor;
			cursor: pointer;
			margin-right: 24px;
		}

		.qs-user-add {
			background: #FFF1E9 url('@assets/images/layout/balance.png') no-repeat 12px center/17px 17px;
			cursor: pointer;
			position: relative;
			margin-right: 20px;
			padding-left: 20px;
			font-size: 14px;
			color: #333;
			height: 34px;
			line-height: 36px;
			border-radius: 8px 8px 8px 8px;
			padding: 0 12px 0 34px;

			span {
				color: $themeTextColorB;
				font-size: 16px;
			}

			&:hover {
				.qs-add-dialog {
					display: block;
				}
			}

			.qs-add-dialog {
				display: none;
				position: absolute;
				width: 110px;
				background: #ffffff;
				box-shadow: 0px 2px 6px 0px rgba(242, 242, 242, 0.77);
				border-radius: 6px;
				z-index: 9;
				top: 76%;
				right: -32%;
				padding: 20px 0 5px;

				.qs-add-dialog-item {
					height: 30px;
					text-align: center;
					line-height: 30px;
					margin-bottom: 5px;
					font-size: 14px;

					&:hover {
						color: #2469FF;
					}
				}
			}
		}

		.qs-user-info {
			height: 100%;
			line-height: 70px;
			cursor: pointer;
			color: #333;
			font-size: 14px;
			background: url('@assets/images/layout/avator.png') left center no-repeat;
			background-size: 32px 32px;
			padding-left: 44px;

			&:hover {
				.qs-user-menulist-system {
					display: block;
				}
			}

			.qs-user-menulist-system {
				display: none;
				background: #ffffff;
				position: fixed;
				top: 68px;
				right: 16px;
				z-index: 99;
				width: 100px;
				box-shadow: 0px 0px 5px 1px rgb(220 220 220 / 35%);
				padding: 10px 0 10px;

				.text {
					width: 100%;
					text-align: center;
					margin: 0 auto;
					font-size: 14px;
					color: #333333;
					line-height: 40px;

					&:hover {
						color: $themeColor;
						background: #F6F9FF;
					}
				}
			}

			.qs-user-dialog {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.4);
				z-index: 99;
				display: flex;
				justify-content: right;
				box-sizing: border-box;
				padding: 60px 16px 0 0;

				.qs-invaite-guide {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: absolute;
					top: 210px;
					right: 131px;

					.invite-text {
						width: 90px;
						text-align: center;
						height: 30px;
						line-height: 30px;
						border: 1px solid #ffffff;
						border-radius: 6px;
						font-size: 14px;
						color: #fff;
					}

					.invite-icon {
						display: block;
						width: 136px;
						height: 35px;
						margin-left: 10px;
					}
				}

				.qs-user-menu-list {
					width: 140px;
					background: rgba(0, 0, 0, 0.7);
					box-shadow: 0px 0px 5px 1px rgba(220, 220, 220, 0.35);
					border-radius: 6px;
					padding: 30px 0;
					height: 380px;

					.text {
						width: 84px;
						margin: 0 auto;
						font-size: 14px;
						color: #fefefe;
						line-height: 40px;
					}
				}
			}
		}

		::v-deep .el-dialog {
			display: flex;
			flex-direction: column;
			margin: 0 !important;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// 内容最大可见度
			max-height: 90vh;
		}

		::v-deep .el-dialog .el-dialog__body {
			max-height: 85% !important;
			min-height: 70%;
			overflow-y: auto;
			flex: 1;
		}

		::v-deep .el-icon-bell {
			font-size: 20px;
			// margin-right: 20px;
		}

		.badge-item {
			margin-right: 34px;
		}
	}
</style>