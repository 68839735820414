import vue from 'vue'
// 菜单列表
function menuList(data) {
	return vue.$get('/saas/api/menu/list', data)
}
// 登录接口
function login(data) {
	return vue.$post('/admin/Login/login', data)
}
//发送验证码 
function sendSmsCode(data) {
	return vue.$post('/admin/open/sendSmsCode', data)
}
// 获取图形验证码  
function getVerify(data) {
	return vue.$get('/admin/Login/getVerify', data)
}
// 退出登录 
function loginOut(data) {
	return vue.$post('/admin/Login/logout', data)
}
// 修改密码
function changePassword(data) {
	return vue.$post('/admin/User/changePassword', data)
}
// 用户列表  
function getUserlist(data) {
	return vue.$get('/admin/user/getList', data)
}

// 平台用户
function getAdminList(data) {
	return vue.$get('/admin/user/getAdminList', data)
}

// 渠道用户
function getAgentList(data) {
	return vue.$get('/admin/user/getAgentList', data)
}

// 用户详情
function getUserinfo(data) {
	return vue.$get('/admin/user/getInfo', data)
}
// 新增用户 
function addUser(data) {
	return vue.$post('/admin/user/add', data)
}
// 编辑用户 
function editUser(data) {
	return vue.$post('/admin/user/edit', data)
}
// 重置用户密码
function resetPassword(data) {
	return vue.$post('/admin/user/resetPassword', data)
}
// 账户禁用
function userIsPause(data) {
	return vue.$post('/admin/user/isPause', data)
}
//删除用户 
function deleteUser(data) {
	return vue.$post('/admin/user/del', data)
}
// 角色列表
function getRoleslist(data) {
	return vue.$get('/admin/role/getList', data)
}
//新增角色
function addRole(data) {
	return vue.$post('/admin/role/add', data)
}
//编辑角色
function editRole(data) {
	return vue.$post('/admin/role/edit', data)
}
//删除角色
function delRole(data) {
	return vue.$post('/admin/role/del', data)
}
//日志列表 
function getLogs(data) {
	return vue.$get('/admin/operateLog/getList', data)
}
// 行业列表
function getIndustrylist(data) {
	return vue.$get('/admin/industry/getList', data)
}
//添加行业 
function addIndustry(data) {
	return vue.$post('/admin/industry/add', data)
}
//编辑行业 
function editIndustry(data) {
	return vue.$post('/admin/industry/edit', data)
}
//删除行业 
function delIndustry(data) {
	return vue.$post('/admin/industry/del', data)
}
// 用户禁用 
function changePause(data) {
	return vue.$post('/admin/user/changePause', data)
}
export {
	loginOut,
	menuList,
	login,
	getUserlist,
	getAdminList,  
	getAgentList,
	getUserinfo,
	addUser,
	editUser,
	deleteUser,
	resetPassword,
	userIsPause,
	getRoleslist,
	addRole,
	editRole,
	delRole,
	sendSmsCode,
	getLogs,
	getVerify,
	getIndustrylist,
	addIndustry,
	editIndustry,
	delIndustry,
	changePassword,
	changePause
}